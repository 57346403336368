
.hotGood-container {
    /*height: calc(100% - 40px);*/
    margin: 20px 0;
    /*::v-deep .el-scrollbar__wrap {*/
    /*    overflow-x: hidden;*/
    /*}*/
    .eCharts-item {
        margin-top: 30px;
        padding: 0 20px;
        &:first-child {
            margin-top: 0;
        }
        .item-title {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            .toggle-day {
                .day {
                    cursor: pointer;
                    color: #999;
                    &.current, &:hover {
                        color: #42D7AB;
                    }
                }
            }
        }
        .item-content {
            /*background: #ffa72a;*/
            margin-top: 10px;
            width: 100%;
            height: 340px;
        }
    }
}
