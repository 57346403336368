
.hotGood-container {
    width: 100%;
    /*height: calc(100% - 40px);*/
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    /*padding-bottom: 20px;*/
    /*::v-deep .el-scrollbar__view {*/
    /*    display: flex;*/
    /*    justify-content: space-between;*/
    /*    width: 100%;*/
    /*}*/
    /*::v-deep .el-scrollbar__wrap {*/
    /*    overflow-x: hidden;*/
    /*}*/
    .analysis-left {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        border-right: 1px solid #EAEAEA;
        /*padding: 0 20px;*/
    }
    .eCharts-item {
        margin-top: 30px;
        padding: 0 20px;
        &:first-child {
            margin-top: 0;
        }
        .item-title {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            .toggle-day {
                .day {
                    cursor: pointer;
                    color: #999;
                    &.current, &:hover {
                        color: #42D7AB;
                    }
                }
            }
        }
        .item-content {
            /*background: #ffa72a;*/
            margin-top: 10px;
            width: 100%;
            height: 300px;
        }
    }
}
.gender-distribution {
    display: flex;
    flex-direction: column;
    /*margin-bottom: 50px;*/
    .title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }
    .distribution-chart {
        .chart-icon {
            display: flex;
            justify-content: space-between;
            color: #34aa7f;
            margin-top: 10px;
            & i {
                font-size: 24px;
            }
        }
        .chart-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 20px;
            margin-top: 10px;
            .man {
                background-color: #34aa7f;
            }
            .woman {
                flex: 1;
                background-color: #46dbab;
            }
        }
        .legend {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .legend-man {
                display: flex;
                flex-direction: column;
            }
            .legend-woman {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
.class-list {
    width: 50%;
    height: 1%;
    flex: 1;
    .analysis-right {
        display: flex;
        flex-direction: column;
        /*width: 50%;*/
        height: 100%;
        padding: 0 20px;

        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            padding-right: 5px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
        }
        .content {
            flex: 1;
            overflow: auto;
            padding-right: 5px;
        }
        .area-distribution {
            flex: 1;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            height: calc(100% - 66px);
            .area-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .area-name {
                    width: 50px;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .percentage {
                    width: 60px;
                    text-align: right;
                }
                ::v-deep .el-progress {
                    width: 1%;
                    flex: 1;
                    padding: 0 10px;
                }
                ::v-deep .el-progress-bar__inner {
                    background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                }
            }
        }
    }
}
