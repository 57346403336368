
.promoteGood-container {
    padding: 20px;
    /*::v-deep .el-table__empty-block {*/
    /*    height: calc(100vh - 380px - 55px)!important;*/
    /*}*/
}
// 表格内样式
.listTable {
    .goods-wrapper {
        display: flex;
        align-items: center;
        .goods-cover {
            width: 80px;
            height: 104px;
            overflow: hidden;
            background: #f3f3f6;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-title {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            color: #333;
        }
        &:hover {
            .goods-title {
                color: #1E33E3;
            }
        }
    }
    .author-name {
        white-space: nowrap;
        color: #2338E6;
    }
}
