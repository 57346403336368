
.good-broad-caster {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    .goods-detail {
        display: flex;
        align-items: center;
        .goods-img {
            width: 34px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-info {
            display: flex;
            flex-direction: column;
            .fans-num {
                font-size: 14px;
                color: #999999;
            }
        }
    }
    .goods-video-detail {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #333;
        &:hover {
            color: #1E33E3;
        }
        .goods-img {
            width: 64px;
            height: 89px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-info {
            width: calc(100% - 74px);
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            a {
                color: #1A1A1A;
            }
        }
    }
}
.pages-center {
    width: 100%;
    flex: 1 1 0%;
    height: calc(100% - 52px);
}
